<template>
  <v-container class="mypage" :style="!isMobile ? 'width: 100%' : ''">
    <v-row>
      <v-col cols="12" class="mypage_tit">
        <h2>마이페이지</h2>
        <v-btn v-if="login_yn == false" color="primary" depressed @click="login">
          로그인
        </v-btn>

        <v-btn v-else color="primary" depressed @click="logout">
          로그아웃
        </v-btn>
      </v-col>

      <v-col cols="12" class="mypage_user">
        <ul>
          <li v-for="item in info_datas" :key="item.menu">
            <div @click="toDetail(item)">
              <div v-html="item.icon"></div>
              <span>{{ item.menu }}</span>
            </div>
          </li>
        </ul>
      </v-col>

      <v-col cols="12" class="mypage_company">
        <ul>
          <li v-for="item in notice_datas" :key="item.menu">
            <div @click="toDetail(item)">
              <div v-html="item.icon"></div>
              <p>{{ item.menu }}</p>
              <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.24264 0.757359L5.48528 5L1.24264 9.24264" stroke="#777777" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </li>
        </ul>
      </v-col>

      <!-- <v-divider class="ml-3 mr-3"></v-divider> -->
      <v-col cols="12" class="mt-2 mb-5" v-if="login_yn == true && adminUser">
        <v-subheader>Admin</v-subheader>
        <v-simple-table>
          <tbody>
            <tr v-for="(item, i) in admin_datas" :key="i" @click="adminCheck(item)">
              <td class="d-flex align-center">
                <img :src="item.icon" class="mr-2" />
                {{ item.menu }}
              </td>
              <td class="text-right">
                <v-icon> chevron_right </v-icon>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>



      <!-- *************************************************************************** -->
      <!-- Dialog : noData_dialog Start  ********************************************* -->
      <v-dialog v-model="noData_dialog" max-width="290">
        <v-card>
          <v-card-title class="text-h5"> 안내 </v-card-title>

          <v-card-text> 거래한 내역이 없습니다 </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="noData_dialog = false">
              확인
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog : noData_dia End  ***************************************************** -->
      <!-- ****************************************************************************** -->

      <!-- *************************************************************************** -->
      <!-- Dialog : Ask Start  ******************************************************* -->
      <v-dialog v-model="ask_dialog" max-width="290">
        <v-card>
          <v-card-title class="text-h5"> 관리자확인 </v-card-title>

          <v-col>
            <v-text-field label="password" v-model="password"></v-text-field>
          </v-col>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="ask_dialog = false">
              닫기
            </v-btn>

            <v-btn color="green darken-1" text @click="passYn">
              확인
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Dialog : Ask End  ***************************************************** -->
      <!-- ****************************************************************************** -->


    </v-row>

    <v-row class="footer" :class="!isMobile ? 'fix-footer' : ''">
      <v-col class="ma-0 pa-0">
        <OtcFooter></OtcFooter>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import axios from '../../service/axios'
import firebase from "firebase";

export default {
  name: "MyInfo",
  components: {
    OtcFooter: () => import("@/components/OtcFooter"),
  },
  data() {
    return {
      // login_yn: false,
      info_datas: [
        {
          menu: "회원정보",
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.8187 13.8048C18.1721 13.5755 17.4801 13.4444 16.7427 13.4444H7.03504C3.69989 13.4444 1 16.0437 1 19.2545V22.7778H22.7778V19.2545C22.7778 16.7536 21.1215 14.613 18.8187 13.8048Z" stroke="#333333" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.5555 5.66667C16.5555 8.24661 14.4688 10.3333 11.8888 10.3333C9.30889 10.3333 7.22217 8.24661 7.22217 5.66667C7.22217 3.08672 9.30889 1 11.8888 1C14.4688 1 16.5555 3.08672 16.5555 5.66667Z" stroke="#333333" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>`,
          link: "/detail/userinfo",
        },
        {
          menu: "판매/구매 내역",
          icon: `<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.5556 10.1493C25.5556 8.48473 24.3114 7.1389 22.7726 7.1389H3.78296C2.24415 7.1389 1 8.48473 1 10.1493C1 11.8139 2.23105 13.1385 3.75677 13.1597C3.75677 13.2731 3.74368 13.3864 3.75677 13.4997L4.47052 22.0281C4.63422 23.9689 6.23852 25.5556 8.03926 25.5556H18.5163C20.317 25.5556 21.9213 23.9689 22.085 22.0281L22.7988 13.4997C22.8119 13.3793 22.7988 13.2731 22.7988 13.1597C24.3245 13.1456 25.5556 11.8068 25.5556 10.1493Z" stroke="#333333" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.78198 6.91452L10.3425 1" stroke="#333333" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.7732 6.91452L16.2126 1" stroke="#333333" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.82495 15.6124V18.3957" stroke="#333333" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.7307 15.6124V18.3957" stroke="#333333" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>`,
          link: "/detail/history",
        },
        {
          menu: "사용내역",
          icon: `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.8484 1.44444H4.15135C2.65631 1.44444 1.44434 2.61296 1.44434 4.05439V21.9456C1.44434 23.387 2.65631 24.5556 4.15135 24.5556H21.8484C23.3435 24.5556 24.5554 23.387 24.5554 21.9456V4.05439C24.5554 2.61296 23.3435 1.44444 21.8484 1.44444Z" stroke="#333333" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.6001 8.2L19.4001 8.2" stroke="#333333" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.6001 13L19.4001 13" stroke="#333333" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.6001 17.8H13.0001" stroke="#333333" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>`,
          link: "/detail/delhistory",
        },
      ],
      notice_datas: [
        {
          menu: "공지사항",
          icon: `<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.03393 4.69232C2.80536 4.69232 1 6.6202 1 9.00001C1 11.3798 2.80536 13.3077 5.03393 13.3077H7V4.69232H5.03393Z" stroke="#2B2B2B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.62848 3.94978C7.24889 4.10168 7 4.46935 7 4.87821V13.1218C7 13.5306 7.24889 13.8983 7.62848 14.0502L13.6285 16.4512C14.2854 16.714 15 16.2303 15 15.5227V2.47725C15 1.76973 14.2854 1.28597 13.6285 1.54883L7.62848 3.94978Z" stroke="#2B2B2B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>`,
          link: "/detail/notice",
        },
        {
          menu: "고객센터",
          icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 8.5C3 6.5 5.2 2 10 2C14.8 2 17 6.5 17 8.5" stroke="black" stroke-width="1.3"/>
            <path d="M17 13C17 15 15.5 17.5 13 17.5" stroke="black" stroke-width="1.3"/>
            <mask id="path-3-inside-1_30_3943" fill="white">
            <rect x="1" y="8" width="4" height="6" rx="1"/>
            </mask>
            <rect x="1" y="8" width="4" height="6" rx="1" stroke="black" stroke-width="2.6" mask="url(#path-3-inside-1_30_3943)"/>
            <mask id="path-4-inside-2_30_3943" fill="white">
            <rect x="15" y="8" width="4" height="6" rx="1"/>
            </mask>
            <rect x="15" y="8" width="4" height="6" rx="1" stroke="black" stroke-width="2.6" mask="url(#path-4-inside-2_30_3943)"/>
            <circle cx="11.5" cy="17.5" r="1.5" stroke="black" stroke-width="1.3"/>
          </svg>`,
          link: "/detail/servicecenter",
        },
        {
          menu: "약관 및 정책",
          icon: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.81085 2.40542C3.81085 3.18098 3.18098 3.81085 2.40542 3.81085C1.62987 3.81085 1 3.18098 1 2.40542C1 1.62987 1.62987 1 2.40542 1C3.18098 1 3.81085 1.62987 3.81085 2.40542Z" stroke="#111111" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.68335 2.40543H15.1887" stroke="#111111" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.81085 7.93286C3.81085 8.70841 3.18098 9.33828 2.40542 9.33828C1.62987 9.33828 1 8.70841 1 7.93286C1 7.15731 1.62987 6.52744 2.40542 6.52744C3.18098 6.52744 3.81085 7.15731 3.81085 7.93286Z" stroke="#111111" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.68335 7.93286H15.1887" stroke="#111111" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.81085 13.4646C3.81085 14.2401 3.18098 14.87 2.40542 14.87C1.62987 14.87 1 14.2401 1 13.4646C1 12.689 1.62987 12.0591 2.40542 12.0591C3.18098 12.0591 3.81085 12.689 3.81085 13.4646Z" stroke="#111111" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.68335 13.4646H15.1887" stroke="#111111" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>`,
          link: "/detail/termservice",
        },
      ],
      admin_datas: [
        {
          menu: "DelHistory",
          icon: "svg/service_center.svg",
          link: "/admin/DelHistory",
        },
      ],

      ask_dialog: false,
      password: "",
      selected_item: null,
      noData_dialog: false,
      setPassword: "otc1748",
      adminUser: false,
      isMobile: false,
    }; // return
  },

  computed: {
    login_yn() {
      let result = false
      let isLogin = this.$store.getters.getUserData
      if (isLogin) {
        result = true
      }
      return result
    },
  },

  created() {
    window.scrollTo(0, 0);
    // console.log("Scroll.....");

    // if (this.$cookies.get("token")) {
    //     this.login_yn = true;
    // }

    let userCheck = this.$store.getters.getUserData;
    // console.log('Check user : ', userCheck)
    if (userCheck) {
      if (
        userCheck.user_email === "mothcar@gmail.com" ||
        userCheck.user_email === "mothcar@naver.com"
      )
        this.adminUser = true;
    }

    window.addEventListener('resize', this.resizeEventHandler);
  },
  mounted() {
    this.resizeEventHandler();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeEventHandler);
  },
  methods: {
    adminCheck(item) {
      console.log("clcicked.. item : ", item);
      this.ask_dialog = true;
      this.selected_item = item;
    },

    passYn() {
      if (this.password == this.setPassword) {
        const item = this.selected_item;
        this.$store.commit({
          type: "setSubPage",
          sub_page: item.menu,
        });
        this.$router.push(item.link);
      }
    },

    toDetail(item) {
      // console.log('Item.......: ', item)
      if (item.menu === "판매/구매 내역") {
        this.noData_dialog = true;
        return;
      }
      console.log(item)
      this.$store.commit({
        type: "setSubPage",
        sub_page: item.menu,
      });
      this.$router.push(item.link);
    },

    login() {
      this.$store.commit({ type: "setSubPage", sub_page: "로그인", });
      this.$store.commit({ type: "setMainPage", main_page: 3 })
      // this.$store.commit({ type: "setHomeState", home_state: 'map' })
      // this.$store.commit({ type: "setSubPage", home_state: 'main' })
      this.$router.push("/sign/login");
    },

    logout() {
      this.$cookies.remove("token");
      // this.login_yn = false;
      // console.log("Token : ", this.$cookies.get("token"));
      this.$store.commit({ type: "setUserData", userData: null });
      this.$store.commit({ type: "setBalance", balance: 0 });

      if (firebase.auth()) {
        firebase.auth().signOut();
      }
    },
    myauth() {
      console.log("본인인증 시작");
      // var IMP = window.IMP;
      // IMP.init("imp90511379");

      // IMP.certification({ // param
      //     merchant_uid: "ORD20180131-0000011"
      // }, function (rsp) { // callback
      //     if (rsp.success) {
      //         console.log('인증성공', rsp)
      //     } else {
      //         console.log('인증실패', rsp)
      //     }
      // });
      // console.log('본인인증 끝');

      // var clientKey = 'test_ck_d26DlbXAaV021bYM5Db8qY50Q9RB';
      // var tossPayments = window.TossPayments(clientKey);
      // console.log(tossPayments);

      // console.log(window.location.origin + '/success')
      // console.log(window.location.origin + '/fail')

      // tossPayments.requestPayment('카드', {
      //     amount: 15000,
      //     orderId: 'yntFQZdks-fVaxumIBju1',
      //     orderName: '토스 티셔츠 외 2건',
      //     customerName: '박토스',
      //     successUrl: window.location.origin + '/success',
      //     failUrl: window.location.origin + '/fail',
      // });
    },
    resizeEventHandler() {
      const { userAgent } = window.navigator;
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
    }
  },
};
</script>

<style lang="scss">
.preview_img {
  .v-image__image--cover {
    background-size: contain;
  }
}

.preview {
  .v-btn--icon.v-size--small {
    width: 10px;
    height: 10px;
  }
}

.subheader {
  font-size: 0.85rem;
  align-self: center;
}

.service_center {
  font-size: 12px;
  letter-spacing: -0.001em;
}

.myinfo_icon_color {
  color: #a3a3a3 !important;
}

.my_footer {
  height: 100px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 10%;
}

#header {
  position: relative;
  //   min-height: 500px;
}

#header-content {
  position: absolute;
  bottom: 0;
  left: 0;
}

// mixin
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-align-center {
  display: flex;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// 변수
$color_main: #FE9900;

.mypage {
  padding: 30px 0px;

  &_tit {
    width: 100%;
    height: auto;
    padding: 0 20px 30px;
    @include flex-between;

    h2 {
      font-size: 1.5rem;
    }

    .v-btn {
      width: auto;
      height: auto !important;
      padding: 6px 20px !important;
      border-radius: 25px;
      background: $color_main;
      color: #fff;
    }
  }

  &_user {
    width: 100%;
    height: auto;
    padding: 0 20px;

    ul {
      width: 100%;
      height: 90px;
      display: flex;
      border-radius: 10px;
      background: #F9F9F9;
      padding-left: 0;

      li {
        flex: 1;
        @include flex-column-center;
        gap: 10px;
        position: relative;

        p {
          padding-bottom: 0;
        }
  
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 40px;
          background: #E5E5E5;
        }
  
        &:last-child::after {
          display: none;
        }

        div {
          cursor: pointer;
          text-align: center;

          span {
            font-size: 0.8125rem;
            font-weight: 600;
            color: #666;
          }
        }
      }
    }
  }

  &_company {
    width: 100%;
    height: auto;
    padding: 20px 20px 30px;

    ul {
      padding-left: 0 !important;

      li {
        width: 100%;
        height: 60px;
        padding-right: 20px; 
        border-bottom: 1px solid #F1F1F1;
        cursor: pointer;
        @include flex-between;
  
        &:last-child {
          border-bottom: 0;
        }
  
        div {
          font-size: 0.938rem;
          font-weight: 600;
          color: #111;
          @include flex-align-center;
          gap: 15px;
          margin-bottom: 0;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .footer {
    &.fix-footer {
      position: relative;
      // bottom: 97px;
      width: 100%;
      max-width: 395px;
    }
  }
}
</style>
